<template>
  <div>
    <!-- 公共头部 -->
<Header></Header>

    <!-- <div class="section1">
			<img class="pic" src="@/assets/picture/about.jpg" />
			<div class="content">
				<h2 class="title">碳索培职业培训学校</h2>
				<div class="desc">碳索培职业培训学校，秉承严格规范办学的原则，以安全技术为前提，大力加强安全管理人员、特殊工种作业、职业技能人才的培养，为社会发展做出必要的贡献。</div>
			</div>
		</div> -->
    <div class="section2">
      <!-- <h2 class="cate-title">详细介绍</h2> -->
      <div class="singlepage" v-html="singlepage"></div>
    </div>

    <!-- 公共底部 -->
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Header from "@/components/Public/Header.vue";
import Footer from "@/components/Public/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      title: "",
      singlepage: "",
    };
  },
  created() {
    this.getSinglePage();
  },
  methods: {
    async getSinglePage() {
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.get("/other/danye", {
        params: { id: 1 },
      });
      this.$toast.close();
      if (this.$store.state.beta) {
        console.log(
          "平台简介详情：" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          res
        );
      }
      var code = res.code;
      if (code == 1) {
        this.singlepage = res.data.content;
      } else {
        this.singlepage = "";
      }
    },
  },
};
</script>

<style scoped>
.section1 {
  font-size: 0;
}
.section1 .pic {
  width: 100%;
}
.section1 .content {
  background: #ffffff;
  padding: 0.3rem;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
}
.section1 .content .title {
  font-size: 0.32rem;
  color: #000000;
}
.section1 .content .desc {
  font-size: 0.24rem;
  color: #000000;
  line-height: 0.42rem;
  margin-top: 0.24rem;
}

.section2 {
  /* margin-top: 0.33rem; */
  margin-bottom: 0.7rem;
}
.section2 .cate-title {
  height: 0.9rem;
  display: flex;
  align-items: center;
  font-size: 0.32rem;
  color: #333333;
  padding-left: 0.5rem;
  position: relative;
}
.section2 .cate-title::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0.05rem;
  height: 0.26rem;
  background: #ff6000;
  left: 0.3rem;
  top: 50%;
  transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
}
.section2 .singlepage {
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.5rem;
  background: #ffffff;
  padding: 0.3rem;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
}
/deep/ .section2 .singlepage img {
  width: 100% !important;
  height: auto !important;
}
</style>